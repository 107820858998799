<template>
  <!-- 我的考试 -->
  <div class="foxRight">
    <div class="dis_flex_between">
      <div class="tabs">
        <div v-for="(item, index) in TabsList" :key="index" class="tab-item" :class="tabActive == index ? 'on' : ''"
          @click="changeTabs(index)">
          {{ item.name }}
        </div>
      </div>
      <!-- <el-input
        style="width: 260px"
        v-if="tabActive != 0"
        placeholder="请输入内容"
        v-model="search.search"
      >
        <i
          slot="append"
          @click="getMyPaperList"
          class="el-input__icon el-icon-search"
        ></i>
      </el-input> -->
      <span v-if="tabActive == 0" class="records" @click="goToRecord">练习记录</span>
      <el-radio-group v-else-if="tabActive != 2" v-model="search.IsPurchase" class="redRadio" @change="searchClick">
        <el-radio :label="1">套餐赠送</el-radio>
        <el-radio :label="0">单独购买</el-radio>
      </el-radio-group>
    </div>
    <!-- 专属题库 -->
    <div v-if="tabActive == 0" class="classes-question">
      <el-collapse v-model="activeNames" class="kcList" accordion>
        <el-collapse-item v-for="(item, index) in questionName" :key="index" :name="index">
          <template slot="title">
            <div class="title">
              <div class="line" />
              <span>{{ item.name }}</span>
            </div>
          </template>
          <!-- 客观题题库 -->
          <question-item v-if="index == 0" style-class="blueOne" :is-purchase="true" :is-personal="true"
            :question-index="index" :question-list="questionList" />
          <!-- 易错错题库 -->
          <question-item v-else-if="index == 1" style-class="blueTwo" :is-purchase="true" :is-personal="true"
            :question-index="index" :question-list="fallibleQuestionList" />
          <!-- 个人错题库 -->
          <question-item v-else-if="index == 2" style-class="blueTwo" :is-purchase="true" :is-personal="true"
            :question-index="index" :question-list="myWrongQuestionList" />
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 试卷 -->
    <template v-else>
      <!-- 试卷包 -->
      <div class="list-two">
        <div v-for="(item, index) in myPaperList" :key="index" class="item">
          <template v-if="item.type == 1">
            <img class="item-img" src="@/assets/img/homeSeventh/examPage.png" />
            <div class="item-content">
              <div class="item-text elp">{{ item.name }}</div>
              <div class="item-tips">
                <div class="tips-text">
                  共{{
                    item.paperNameList ? item.paperNameList.length : 0
                  }}套试卷
                </div>
                <div v-if="search.IsPurchase == 1" class="m-r-4 m-l-4">/</div>
                <div v-if="search.IsPurchase == 1" class="tips-text">
                  {{ item.className }}
                </div>
              </div>
            </div>
            <div class="colleges-learn fles-shrink" @click.stop="goOrder(item, 6)">
              {{ wrongTopic ? '练习' : '查看试卷' }}
            </div>
          </template>
          <template v-else>
            <div class="flexRow">
              <img class="item-image" src="@/assets/img/homeSeventh/quesTionIcon.png" />
              <div class="item-content">
                <div class="item-text ellipsis">{{ item.name }}</div>
                <div class="item-tips">
                  <div v-if="wrongTopic" class="tips-text">
                    共{{ item.subjectNum ? item.subjectNum : 0 }}题
                  </div>
                  <div v-if="search.IsPurchase == 1 && item.className" class="m-r-4 m-l-4">
                    /
                  </div>
                  <div v-if="search.IsPurchase == 1 && item.className" class="tips-text">
                    {{ item.className }}
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="keep-learn" @click.stop="goOrder(item, 12)">立即购买</div> -->
            <div class="colleges-learn fles-shrink" @click="goLearn(item)">
              {{ wrongTopic ? '练习' : '查看试卷' }}
            </div>
            <!--   <div v-else class="flex_Box">
              <div style="margin-right: 10px" class="keep-learn viewTopic">查看解析</div>
              <div @click="goLearn(item)" class="keep-learn">重新答题</div>
            </div> -->
          </template>
        </div>
      </div>
      <div v-if="!myPaperList || myPaperList.length == 0" class="nothing">
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <el-pagination class="pagination colleges-pagination" :total="total" :current-page="search.pageNum"
        :page-sizes="[8, 16, 25, 40]" :page-size="search.pageSize" :pager-count="5" background
        layout="total, sizes, prev, pager, next" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" />
    </template>
  </div>
</template>
<script>
import TypeChooseView from '@/components/typeChooseView.vue'
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import { AnswerClient } from '@/api/task/answerClient'
import {
  getQuestionStudentCourseList,
  getFallibleStudentCourseList,
  getStudentCourseList
} from '@/api/question'
import ExamPlace from '@/views/quesTion/examPlace/examPlace.vue'
import questionItem from '@/components/Know/classCom/question-item.vue'
const answerClient = new AnswerClient()
export default {
  components: { TypeChooseView, ExamPlace, questionItem },
  data() {
    return {
      myPaperList: [], // 试卷列表
      getInfo: null,
      wrongTopic: false,
      tabActive: 0,
      questionName: [
        { name: '客观题题库' },
        { name: '易错错题库' },
        { name: '个人错题库' }
      ],
      TabsList: [{ name: '专属题库' }, { name: '试卷' }, { name: '错题库' }],
      activeNames: [0],
      questionList: [],
      fallibleQuestionList: [],
      myWrongQuestionList: [],
      search: {
        pageSize: 8,
        pageNum: 1,
        IsPurchase: 1 // 1赠送，0购买
        // search: "", // 关键字
      },
      total: 0,
      other: [
        // { fId: 4001, fName: "虚拟考场" },
        { fId: 4002, fName: '考点练习' },
        { fId: 4003, fName: '每日一练', path: '/all/examPlace/answer?type=2' }
      ],
      /* 考点讲解显示 */
      ifPlace: false,
      thisList: null
    }
  },
  async created() {
    this.userInfo = getInfo()
    await this.getQuestionList()
  },
  methods: {
    submitShiJuan(item) {
      this.$router.replace({
        path: `/quesTion/examDetails?title=${item.title}&paperId=${this.paperId}&IsPurchase=true&type=12&examType=${this.examType}`
      })
    },
    goOrder(item) {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录，无法做题, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      const sub = {
        packId: item.id,
        classId: item.classId
      }
      const data = Vue.prototype.export(sub)
      this.$router.push(`/all/examPack?${data}`)
    },
    // 获取试卷包和试卷整合列表
    async getMyPaperList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      const { rows, total } = await answerClient.getPcMyPackageAndPaper(
        this.search
      )
      this.myPaperList = rows
      this.total = total
      loading.close()
    },
    // 获取错题库整合列表
    async getMistakesPaperList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      const { rows, total } = await answerClient.getMistakesPaperList(
        this.search.pageSize,
        this.search.pageNum
      )
      this.myPaperList = rows
      this.total = total
      loading.close()
    },
    // 切换试卷
    searchClick(val) {
      this.search.pageNum = 1
      this.search.IsPurchase = val
      this.myPaperList = []
      this.total = 0
      this.getMyPaperList()
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      if (this.tabActive == 2) {
        this.getMistakesPaperList()
      } else {
        this.getMyPaperList()
      }
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      if (this.tabActive == 2) {
        this.getMistakesPaperList()
      } else {
        this.getMyPaperList()
      }
    },
    /* 试卷b做题 */
    goPage(item) {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录，无法做题, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      this.$router.push(`/all/examPack?packId=${item.id}`)
    },
    /* 试卷做题 */
    goLearn(item) {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录，无法做题, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }

      const sub = {
        id: item.id,
        classId: item.classId,
        title: item.name,
        type: 12,
        IsPurchase: true,
        paperId: item.id,
        examType: 4
        // wrongTopic: item.wrongTopic
      }
      if (this.wrongTopic) {
        sub.wrongTopic = true
      }
      const data = Vue.prototype.export(sub)
      data

      this.$router.push(`/quesTion/examDetails?${data}`)
    },
    /* 进入考点*/
    goPlace(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView
        return
      }
      this.ifPlace = true
      this.thisList = item
    },
    // 专属题库/试卷筛选
    changeTabs(val) {
      this.tabActive = val
      // 专属题库
      if (val == 0) {
        this.wrongTopic = false
        this.getQuestionList()
      } else if (val == 2) {
        // 错题库
        this.wrongTopic = true
        this.search.IsPurchase = 1
        this.myPaperList = []
        this.total = 0
        this.getMistakesPaperList()
      } else {
        // 试卷
        this.wrongTopic = false
        this.search.IsPurchase = 1
        this.myPaperList = []
        this.total = 0
        this.getMyPaperList()
      }
    },
    // 获取专属题库三个题型
    async getQuestionList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      // 客观题题库
      const { data } = await getQuestionStudentCourseList()
      // 易错错题库
      const { data: data2 } = await getFallibleStudentCourseList()
      // 个人错题库
      const { data: data3 } = await getStudentCourseList()
      this.questionList = data || []
      this.fallibleQuestionList = data2 || []
      this.myWrongQuestionList = data3 || []
      loading.close()
    },
    goToRecord() {
      this.$router.push({
        path: `/all/examinationRecord`
      })
    }
  }
}
</script>
<style lang="less" scoped>
.foxRight {
  background: #ffffff;
  padding-bottom: 20px;

  .dis_flex_between {
    padding: 24px 32px 0 0;
    background: #fff;
    height: 50px;
    align-items: center;

    .tabs {
      display: flex;
      align-items: center;
      color: #666666;
      font-size: 14px;
      width: 272px;
      height: 38px;
      background: #f5f7f9;
      border-radius: 6px 6px 6px 6px;

      .tab-item {
        cursor: pointer;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #5a5d5a;
        text-align: center;
        width: 132px;
        height: 38px;
        line-height: 38px;
      }

      .on {
        color: #4a6af0;
        width: 132px;
        height: 30px;
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        line-height: 30px;
        margin: 4px;
      }
    }

    .records {
      width: 84px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #4a6af0;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #4a6af0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    /deep/ .el-radio-group {
      .el-radio {
        margin-right: 20px;
      }

      .el-cascader-node>.el-radio,
      .el-radio:last-child {
        margin-right: 0;
      }

      .el-radio__label {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #5a5d5a;
      }
    }
  }

  /deep/.redRadio {
    .is-checked {
      .el-radio__inner {
        border-color: #4a6af0;
        background: #4a6af0;
      }
    }
  }
}

/* 试卷列表 */
.list-two {
  background: #fff;
  padding: 0px;

  .item {
    height: auto;
    padding: 24px 0px;
    margin: 0px;
    border-bottom: 1px solid #eeeeee;
    border-radius: 0px;

    .item-img {
      width: 48px;
      height: 56px;
      flex-shrink: 0;
      margin-right: 12px;
    }

    .item-content {
      width: 100%;
      height: 56px;
    }
  }
}

.item-content {
  width: 100%;
  height: 100%;

  .item-text {
    max-width: 570px;
    height: 16px !important;
    font-size: 16px !important;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400 !important;
    color: #333333 !important;
    line-height: 16px !important;
  }

  .item-tips {
    margin-top: 12px;
    display: flex;
    align-items: center;
    height: 12px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 12px;

    .tips-text {
      height: 12px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
    }
  }
}

.flexRow {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .item-image {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    margin-right: 12px;
  }

  .item-content {
    height: 40px;
  }
}

.flex_Box {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.classes-question {
  padding: 24px 32px 31px 0;
  background: #ffffff;

  /deep/ .el-collapse {
    border: none;

    .el-collapse-item__header {
      border: none;
    }

    .el-collapse-item__wrap {
      border-bottom: #f5f5f5 1px solid;
    }

    .el-icon-arrow-right {
      transform: rotate(90deg);
    }

    .is-active.el-icon-arrow-right {
      transform: rotate(270deg);
    }
  }

  .title {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    display: flex;
    align-items: center;

    .line {
      width: 3px;
      height: 16px;
      background: #4a6af0;
      border-radius: 2px 2px 2px 2px;
      margin-right: 9px;
      display: inline-block;
    }
  }
}
</style>